import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaArrowLeft, FaArrowRight, FaSearchPlus, FaTimes, FaFilePdf } from 'react-icons/fa';
import Navbar from '../../components/Navbar/NavBar';

// Import product images
import img1 from '../../images/products/M1_P4.1.png';
import img2 from '../../images/products/M1_P4.2.png';
import img3 from '../../images/products/M1_P4.3.png';
import img4 from '../../images/products/M1_P4.4.png';

// Import PDF
import productPDF from '../../Manuals/P4.pdf';

const Product4 = () => {
  const navigate = useNavigate();
  const [currentImage, setCurrentImage] = useState(0);
  const [isImageModalOpen, setIsImageModalOpen] = useState(false);
  const [isPDFModalOpen, setIsPDFModalOpen] = useState(false);
  const images = [img1, img2, img3, img4];

  useEffect(() => {
      window.scrollTo(0, 0);
  }, []);

  const nextImage = () => {
    setCurrentImage((prev) => (prev + 1) % images.length);
  };

  const prevImage = () => {
    setCurrentImage((prev) => (prev - 1 + images.length) % images.length);
  };

  return (
    <>
      <Navbar />

      <div className="min-h-screen bg-gray-100 flex flex-col items-center pt-24 p-6">
        <h1 
          className="text-7xl font-extrabold text-blue-900 mb-12 text-center" 
          style={{ fontFamily: 'Congenial Black' }}
        >
          Alarm Annunciator
        </h1>

        <div className="flex flex-col sm:flex-row items-start w-full max-w-6xl">
          {/* Image Carousel */}
          <div className="relative w-[500px] flex flex-col items-center">
            <div className="relative w-full">
              <button 
                className="absolute left-2 top-1/2 transform -translate-y-1/2 bg-gray-700 text-white p-2 rounded-full transition hover:bg-gray-900" 
                onClick={prevImage}
              >
                <FaArrowLeft />
              </button>

              <img 
                src={images[currentImage]} 
                alt="Alarm Annunciator - Model 4X" 
                className="w-full rounded-lg shadow-lg cursor-pointer"
              />

              <button 
                className="absolute right-2 top-1/2 transform -translate-y-1/2 bg-gray-700 text-white p-2 rounded-full transition hover:bg-gray-900" 
                onClick={nextImage}
              >
                <FaArrowRight />
              </button>

              {/* Magnify Button */}
              <button 
                className="absolute top-2 right-2 bg-gray-700 text-white p-2 rounded-full transition hover:bg-gray-900"
                onClick={() => setIsImageModalOpen(true)}
              >
                <FaSearchPlus />
              </button>
            </div>

            {/* PDF Button below Image */}
            <button 
              className="mt-4 px-6 py-2 bg-red-600 text-white flex items-center gap-2 rounded-lg hover:bg-red-800 transition"
              onClick={() => setIsPDFModalOpen(true)}
            >
              <FaFilePdf className="text-xl" />
              View PDF
            </button>
          </div>

          {/* Product Details */}
          <div className="ml-10 max-w-lg bg-white p-6 rounded-lg shadow-lg">
            <h2 className="text-2xl font-semibold mb-3 text-gray-800">Specifications</h2>
            <ul className="list-disc list-inside text-gray-700">
              <li><strong>Brand:</strong> Gravity Techno</li>
              <li><strong>Model Name/Number:</strong> 4X</li>
              <li><strong>Type:</strong> Wireless</li>
              <li><strong>Number of Output:</strong> 2 Relay</li>
              <li><strong>Audible Range:</strong> 8 PT</li>
              <li><strong>Number of Stages:</strong> 3</li>
              <li><strong>Voltage:</strong> 240 V</li>
              <li><strong>Usage/Application:</strong> Industrial</li>
              <li><strong>Power Source:</strong> Electric</li>
              <li><strong>Display Type:</strong> LED</li>
              <li><strong>Frequency:</strong> 50 Hz</li>
            </ul>

            {/* Key Features Section */}
            <div className="mt-6">
              <h2 className="text-2xl font-semibold mb-3 text-gray-800">Key Features</h2>
              <p className="text-gray-700 mb-4">
                An Alarm Annunciator is an electronic device that records data over time or in relation to location, either with a built-in instrument or via external instruments and sensors.
              </p>
              <ul className="list-disc list-inside text-gray-700">
                <li>Microprocessor-based 4-channel Alarm Annunciator with 2 relay outputs.</li>
                <li>Power-on self-test displays alarm status on corresponding windows.</li>
                <li>Configurable input type: potential-free NO/NC.</li>
                <li>Each input can be assigned to Relay 1 or Relay 2.</li>
                <li>Auto/manual reset selection.</li>
                <li>Built-in buzzer enable/disable option.</li>
                <li>Ring back and first-up features.</li>
                <li>Selectable ring-back and first-up timers (15, 30, 60, 120 min).</li>
                <li>ACK key acknowledges the alarm, turning off output relays and stopping alarm blinking.</li>
                <li>RST key clears previously acknowledged alarms once the condition is removed.</li>
                <li>TST key allows test mode, blinking all 4 alarm windows.</li>
              </ul>
            </div>
          </div>
        </div>

        <button 
          onClick={() => navigate(-1)} 
          className="mt-6 px-6 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-800 transition"
        >
          Go Back
        </button>
      </div>

      {/* Modal for Enlarged Image */}
      {isImageModalOpen && (
        <div 
          className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-75 flex justify-center items-center z-50"
          onClick={() => setIsImageModalOpen(false)}
        >
          <div className="relative max-w-3xl">
            <img 
              src={images[currentImage]} 
              alt="Alarm Annunciator - Model 4X Enlarged" 
              className="w-full rounded-lg"
            />
            <button 
              className="absolute top-3 right-3 bg-gray-700 text-white p-2 rounded-full transition hover:bg-gray-900"
              onClick={() => setIsImageModalOpen(false)}
            >
              <FaTimes />
            </button>
          </div>
        </div>
      )}

      {/* Modal for PDF */}
      {isPDFModalOpen && (
        <div 
          className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-75 flex justify-center items-center z-50"
          onClick={() => setIsPDFModalOpen(false)}
        >
          <div className="relative bg-white p-4 rounded-lg w-3/4 h-3/4 shadow-lg flex flex-col">
            <button 
              className="absolute top-3 right-3 bg-gray-700 text-white p-2 rounded-full transition hover:bg-gray-900"
              onClick={() => setIsPDFModalOpen(false)}
            >
              <FaTimes />
            </button>
            <iframe 
              src={productPDF} 
              className="w-full h-full"
              title="Product PDF"
            />
          </div>
        </div>
      )}
    </>
  );
};

export default Product4;
