import React from "react";
import { useNavigate } from "react-router-dom";

// Import product images
import product1 from '../images/products/M1_P1.1.png';
import product2 from '../images/products/M1_P2.1.png';
import product3 from '../images/products/M1_P3.1.png';
import product4 from '../images/products/M1_P4.1.png';
import product5 from '../images/products/M1_P5.1.png';
import product6 from '../images/products/M1_P6.1.png';
import product7 from '../images/products/M1_P7.1.png';
import product8 from '../images/products/M1_P8.1.png';

const products = [
  { id: 1, name: "Data Logger - Zemlog", image: product1 },
  { id: 2, name: "Data Scanner - Zemscan", image: product2 },
  { id: 3, name: "Safety Controller", image: product3 },
  { id: 4, name: "Alarm Annunciator", image: product4 },
  { id: 5, name: "PLC Controller", image: product5 },
  { id: 6, name: "Process Controller", image: product6 },
  { id: 7, name: "Temperature and RH Controller", image: product7 },
  { id: 8, name: "Digital Vibrator Controller", image: product8 },
];

const Portfolio = () => {
  const navigate = useNavigate();

  return (
    <section className="py-12 bg-gray-100" id="portfolio">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold text-center mb-8">Our Products</h2>
        <div className="flex flex-col items-center">
          <div className="grid grid-cols-1 sm:grid-cols-3 gap-6 mb-6">
            {products.slice(0, 8).map((product) => (
              <button
                key={product.id}
                className="bg-white shadow-lg rounded-lg overflow-hidden p-6 flex flex-col items-center transform transition duration-500 hover:scale-105"
                onClick={() => navigate(`/product/${product.id}`)}
              >
                <img
                  src={product.image}
                  alt={product.name}
                  className="w-full h-80 object-cover object-center rounded-md"
                />
                <div className="mt-4 text-center text-xl font-semibold">{product.name}</div>
              </button>
            ))}
          </div>

          
        </div>
      </div>
    </section>
  );
};

export default Portfolio;
