import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import Notiflix from 'notiflix';
import NavBar from '../components/Navbar/NavBar';
import Footer from '../components/Footer';
import { useDocTitle } from '../components/CustomHook';

const DemoProduct = () => {
    useDocTitle('Gravity Techno - Product Inquiry');
    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();
        
        emailjs.sendForm(
            'service_7afuaye', // Service ID (unchanged)
            'template_kiel9zt', // ✅ Updated Template ID (Replace with actual ID)
            form.current, 
            '3jsExxaTuqGmpSs9_' // Public key (unchanged)
        )
        .then(
            () => {
                Notiflix.Report.success('Success', 'Message sent successfully!', 'Okay');
                form.current.reset(); // Reset form on success
            },
            (error) => {
                Notiflix.Report.failure('Error', 'Failed to send message. Try again.', 'Okay');
                console.error('FAILED...', error);
            }
        );
    };

    return (
        <>
            <NavBar />
            <div id='demoproduct' className="flex justify-center items-center mt-8 w-full bg-white py-12 lg:py-24">
                <div className="container mx-auto my-8 px-4 lg:px-20 flex flex-col lg:flex-row" data-aos="zoom-in">
                    <form ref={form} onSubmit={sendEmail} className="w-full bg-white p-8 my-4 md:px-12 lg:w-3/5 lg:pl-20 lg:pr-40 mr-auto rounded-2xl shadow-2xl">
                        <h1 className="font-bold text-center lg:text-left text-red-900 uppercase text-4xl">Products You Want to Enquire For</h1>

                        <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                            <input type="text" name="first_name" placeholder="First Name*" required className="w-full bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline" />
                            <input type="text" name="last_name" placeholder="Last Name*" required className="w-full bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline" />
                        </div>
                        <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                            <input type="email" name="user_email" placeholder="Email*" required className="w-full bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline" />
                            <input type="tel" name="user_phone" placeholder="Phone*" required className="w-full bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline" />
                        </div>
                        <div className="my-4">
                            <textarea name="message" placeholder="Message and Products Interested*" required className="w-full h-32 bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"></textarea>
                        </div>
                        <button type="submit" className="uppercase text-sm font-bold tracking-wide bg-gray-500 hover:bg-red-900 text-gray-100 p-3 rounded-lg w-full focus:outline-none focus:shadow-outline">
                            Send Message
                        </button>
                    </form>
                    <div className="w-full lg:w-2/5 bg-red-900 text-white p-8 rounded-2xl shadow-2xl lg:ml-8 mt-8 lg:mt-0">
                        <h2 className="text-2xl font-bold">Contact Information</h2>
                        <p className="mt-4">Gala No. 2/217, Nikisha Industrial Estate, Pandurang Wadi, Penkar Pada, Mira Road(E), Thane 401104</p>
                        <p className="mt-4">Phone: +91 9136731192</p>
                        <p className="mt-4">Email: info.gravitytechno@gmail.com</p>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default DemoProduct;
