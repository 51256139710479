import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaArrowLeft, FaArrowRight, FaSearchPlus, FaTimes, FaFilePdf } from 'react-icons/fa';
import Navbar from '../../components/Navbar/NavBar';

// Import product images
import img1 from '../../images/products/M1_P8.1.png';
import img2 from '../../images/products/M1_P8.2.png';
import img3 from '../../images/products/M1_P8.3.png';
import img4 from '../../images/products/M1_P8.4.png';

// Import product PDF
import productPDF from '../../Manuals/P8.pdf';

const Product8 = () => {
  const navigate = useNavigate();
  const [currentImage, setCurrentImage] = useState(0);
  const [isImageModalOpen, setIsImageModalOpen] = useState(false);
  const [isPDFModalOpen, setIsPDFModalOpen] = useState(false);
  const images = [img1, img2, img3, img4];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const nextImage = () => {
    setCurrentImage((prev) => (prev + 1) % images.length);
  };

  const prevImage = () => {
    setCurrentImage((prev) => (prev - 1 + images.length) % images.length);
  };

  return (
    <>
      <Navbar />

      <div className="min-h-screen bg-gray-100 flex flex-col items-center pt-24 p-6">
        {/* Product Title */}
        <h1 className="text-7xl font-extrabold text-blue-900 mb-12 text-center" style={{ fontFamily: 'Congenial Black' }}>
          Digital Vibrator Controller
        </h1>

        <div className="flex flex-col sm:flex-row items-start w-full max-w-6xl">
          {/* Image Carousel */}
          <div className="relative w-[500px] flex flex-col items-center">
            <div className="relative w-full">
              <button
                className="absolute left-2 top-1/2 transform -translate-y-1/2 bg-gray-700 text-white p-2 rounded-full transition hover:bg-gray-900"
                onClick={prevImage}
              >
                <FaArrowLeft />
              </button>

              <img
                src={images[currentImage]}
                alt="Digital Vibrator Controller"
                className="w-full rounded-lg shadow-lg cursor-pointer"
              />

              <button
                className="absolute right-2 top-1/2 transform -translate-y-1/2 bg-gray-700 text-white p-2 rounded-full transition hover:bg-gray-900"
                onClick={nextImage}
              >
                <FaArrowRight />
              </button>

              {/* Magnify Button */}
              <button
                className="absolute top-2 right-2 bg-gray-700 text-white p-2 rounded-full transition hover:bg-gray-900"
                onClick={() => setIsImageModalOpen(true)}
              >
                <FaSearchPlus />
              </button>
            </div>

            {/* PDF Button below Image */}
            <button
              className="mt-4 px-6 py-2 bg-red-600 text-white flex items-center gap-2 rounded-lg hover:bg-red-800 transition"
              onClick={() => setIsPDFModalOpen(true)}
            >
              <FaFilePdf className="text-xl" />
              View PDF
            </button>
          </div>

          {/* Product Details */}
          <div className="ml-10 max-w-lg bg-white p-6 rounded-lg shadow-lg">
            <h2 className="text-2xl font-semibold mb-3 text-gray-800">Specifications</h2>
            <ul className="list-disc list-inside text-gray-700">
              <li><strong>Input Voltage:</strong> 90 – 240 AC (50Hz/60Hz)</li>
              <li><strong>Output Voltage Step:</strong> 1-1.5V</li>
              <li><strong>Max Output Current:</strong> 5A</li>
              <li><strong>OP Voltage Half Wave:</strong> 20-100VDC</li>
              <li><strong>OP Voltage Full Wave:</strong> 30-Vin-10VAC</li>
              <li><strong>Power Consumption:</strong> &lt;5W</li>
              <li><strong>Soft Start Time:</strong> 0-9.9 SEC</li>
              <li><strong>On/Off Delay Time:</strong> 0-9.9 SEC</li>
              <li><strong>Programmable Keys:</strong> 6 Keys</li>
              <li><strong>Status LEDs:</strong> 10 LEDs</li>
              <li><strong>Display:</strong> 5 Digit 7 Segment Display</li>
              <li><strong>Ambient Temperature:</strong> 25 – 45°C</li>
              <li><strong>Ambient Humidity:</strong> 30 – 85%</li>
            </ul>

            {/* Key Features Section */}
            <h2 className="text-2xl font-semibold mt-6 mb-3 text-gray-800">Key Features</h2>
            <ul className="list-disc list-inside text-gray-700">
              <li>Variable Voltage Digital Controller for Vibratory Feeder</li>
              <li>Selection for Input Keypad, POT, and Fine Coarse</li>
              <li>OP can be controlled with Analog IP (1-5V / 4-20mA)</li>
              <li>Programmable Soft Start and Soft End Delay</li>
              <li>2 Logical Digital IP to Start/Stop OP with Delays</li>
              <li>Sensor Power from the Controller</li>
              <li>Programmable Maximum OP Limit, Half Wave, and Full Wave</li>
              <li>Keypad Lock Features</li>
              <li>Status LED Indication and Programmable Keys</li>
            </ul>
          </div>
        </div>

        <button
          onClick={() => navigate(-1)}
          className="mt-6 px-6 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-800 transition"
        >
          Go Back
        </button>
      </div>

      {/* Modal for Enlarged Image */}
      {isImageModalOpen && (
        <div
          className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-75 flex justify-center items-center z-50"
          onClick={() => setIsImageModalOpen(false)}
        >
          <div className="relative max-w-3xl">
            <img
              src={images[currentImage]}
              alt="Digital Vibrator Controller Enlarged"
              className="w-full rounded-lg"
            />
            <button
              className="absolute top-3 right-3 bg-gray-700 text-white p-2 rounded-full transition hover:bg-gray-900"
              onClick={() => setIsImageModalOpen(false)}
            >
              <FaTimes />
            </button>
          </div>
        </div>
      )}

      {/* Modal for PDF */}
      {isPDFModalOpen && (
        <div
          className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-75 flex justify-center items-center z-50"
          onClick={() => setIsPDFModalOpen(false)}
        >
          <div className="relative bg-white p-4 rounded-lg w-3/4 h-3/4 shadow-lg flex flex-col">
            <button
              className="absolute top-3 right-3 bg-gray-700 text-white p-2 rounded-full transition hover:bg-gray-900"
              onClick={() => setIsPDFModalOpen(false)}
            >
              <FaTimes />
            </button>
            <iframe
              src={productPDF}
              className="w-full h-full"
              title="Product PDF"
            />
          </div>
        </div>
      )}
    </>
  );
};

export default Product8;
