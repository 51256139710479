import React from 'react';
import { Link } from 'react-router-dom'; // ✅ Fix: Use Link instead of HashLink
import { HashLink } from 'react-router-hash-link';

const NavLinks = () => {
    return (
        <>
            <Link className="px-4 font-extrabold text-gray-500 hover:text-blue-900" to="/about">
                About
            </Link>
            <HashLink className="px-4 font-extrabold text-gray-500 hover:text-blue-900" smooth to="/#services">
                Services
            </HashLink>
            <HashLink className="px-4 font-extrabold text-gray-500 hover:text-blue-900" to="/products">
                Products
            </HashLink>
            <HashLink className="px-4 font-extrabold text-gray-500 hover:text-blue-900" to="/contact#contact">
                Contact Us
            </HashLink>
            <HashLink className="text-white bg-red-600 hover:bg-red-800 inline-flex items-center justify-center w-auto px-6 py-3 shadow-xl rounded-xl" smooth to="/get-demo#demo">
                Inquire Now
            </HashLink>
        </>
    );
}

export default NavLinks;
