import React, { useEffect, useState } from 'react';

const Counter = ({ end }) => {
    const [count, setCount] = useState(0);

    useEffect(() => {
        let start = 0;
        const duration = 4000;
        const increment = Math.ceil(end / (duration / 50));
        const timer = setInterval(() => {
            start += increment;
            if (start >= end) {
                setCount(end);
                clearInterval(timer);
            } else {
                setCount(start);
            }
        }, 50);

        return () => clearInterval(timer);
    }, [end]);

    return <h2 className="text-4xl font-bold text-black-500">{count}+</h2>;
};

const StatsSection = () => {
    return (
        <div className="bg-red-900 text-white py-12 text-center">
            <h3 className="text-black-500 text-lg font-semibold">__ All Solutions __</h3>
            <h2 className="text-3xl font-bold mt-2">Get A Solution For All Industries</h2>
            
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-8 mt-8 px-6 md:px-16">
                <div>
                    <Counter end={15} />
                    <p className="text-lg font-semibold">Years in Business</p>
                </div>
                <div>
                    <Counter end={500} />
                    <p className="text-lg font-semibold">Happy Clients</p>
                </div>
                <div>
                    <Counter end={100} />
                    <p className="text-lg font-semibold">Products Developed</p>
                </div>
                <div>
                    <Counter end={70} />
                    <p className="text-lg font-semibold">Services Provided</p>
                </div>
            </div>
        </div>
    );
};

export default StatsSection;

