import React from 'react';
import img from '../images/Intro_Image.jpg';
//import { Link } from 'react-router-dom';

const Intro = () => {
    return (
        <>
            <div className="m-auto max-w-6xl p-2 md:p-12 h-5/6" id='about'>

                <div className="flex flex-col-reverse lg:flex-row py-8 justify-between items-center lg:items-start" data-aos="fade-up">
                    {/* Left Section (Image) */}
                    <div className="lg:w-1/2 flex justify-center lg:justify-start">
                        <img alt="card img" className="rounded-lg max-w-full h-auto" src={img} />
                    </div>

                    {/* Right Section (Text) */}
                    <div className="w-full lg:w-1/2 px-6 lg:px-12 flex flex-col justify-center" data-aos="zoom-in" data-aos-delay="500">
                        <h3 className="text-3xl text-red-900 font-bold mb-4">
                            We design and develop high-quality bespoke electronics solutions and embedded systems for organizations, institutions, and SMEs.
                        </h3>
                        
                        <p className='mb-3 text-xl text-gray-600 font-semibold'>
                            Our team is highly skilled in electronics design and development, ready to help create customized solutions tailored to your needs.
                        </p>

                        <p className='mb-3 text-xl text-gray-600 font-semibold'>
                            We take responsibility for building custom electronics systems that automate your processes, enhance operational efficiency, and deliver innovative hardware solutions for your business.
                        </p>

                        {/* 
                        <Link to="/contact" className="text-white bg-red-900 hover:bg-blue-800 inline-flex items-center justify-center w-full px-6 py-2 my-4 text-lg shadow-xl rounded-2xl sm:w-auto sm:mb-0 group">
                            Contact us
                            <svg className="w-4 h-4 ml-1 group-hover: translate-x-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                <path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path>
                            </svg>
                        </Link>
                        */}
                    </div>
                </div>
            </div>
        </>
    )
}

export default Intro;
