import React from 'react';
import NavBar from '../components/Navbar/NavBar';
import aboutImage from '../images/hero2_image.jpg'; // Ensure the path is correct

const About = () => {
    return (
        <>
            <NavBar />
            <section className="pt-32 pb-16 bg-gray-100 min-h-screen"> {/* Increased pt-32 for navbar overlap */}
                <div className="container mx-auto px-6 lg:px-16 flex flex-col lg:flex-row items-center">
                    
                    {/* Image Section */}
                    <div className="lg:w-1/2 w-full flex justify-center mb-6 lg:mb-0">
                        <img 
                            src={aboutImage} 
                            alt="About Us" 
                            className="rounded-lg shadow-lg w-full max-w-md lg:max-w-lg"
                        />
                    </div>
                    
                    {/* Description Section */}
                    <div className="lg:w-1/2 w-full text-center lg:text-left px-4">
                        <h2 className="text-4xl font-bold text-red-900 mb-6">About Us</h2> {/* Increased bottom margin */}
                        <p className="text-gray-700 text-lg leading-relaxed">
                            We are one of the leading industrial process control product companies, managed by professionals with a combined experience of more than 15 years in the field of instrumentation and process control products.
                        </p>
                        <p className="text-gray-700 text-lg leading-relaxed mt-4">
                            Our leadership and managerial team possess in-depth knowledge and expertise in instrumentation, implementing industry best practices to provide high-quality solutions with rigorous process standards.
                        </p>
                        <p className="text-gray-700 text-lg leading-relaxed mt-4">
                            Our product range includes solutions from simple processes to complex automation, offering end-to-end solutions. We also provide unique concept-to-product design services for electronics products, developing custom solutions as per specific requirements.
                        </p>
                    </div>
                </div>
            </section>
        </>
    );
};

export default About;
