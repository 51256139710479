import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
    return (
        <footer>
            <div className="footer max-w-full mx-auto px-4 sm:px-6 bg-gray-100 border-t border-b py-30">
                
                {/* Footer Content */}
                <div className="grid sm:grid-cols-12 gap-5 py-8 md:py-12 border-t border-gray-200 lg:ml-11">

                    {/* 1st Block - Company Info */}
                    <div className="col-span-12 lg:col-span-4">
                        <div className="box-border border-b-4 border-red-900 p-8 bg-gray-200 text-gray-600 text-center rounded-lg xl:w-80 mx-auto">
                            <h3 className="font-bold text-4xl mb-4 text-red-900">Gravity Techno</h3>
                            <div className='text-md font-medium text-gray-600'>
                                <h5>Innovating the Future</h5>
                                <p>Nikisha Industrial Estate,</p>
                                <p>Mira Road Penkarpada,</p>
                                <p>Thane, Maharashtra, India</p>
                            </div>
                        </div>
                    </div>

                    {/* 2nd Block - Navigation Links */}
                    <div className="col-span-6 md:col-span-6 lg:col-span-1 ml-7 mx-auto">
                        <h6 className="text-red-900 text-xl font-bold mb-4">LINKS</h6>
                        <ul className="text-md">
                            <li className="mb-2">
                                <Link to="/about" className="text-red-900 hover:text-gray-900 hover:tracking-wider transition duration-250 ease-in-out">
                                    About
                                </Link>
                            </li>
                            <li className="mb-2">
                                <Link to="/services" className="text-red-900 hover:text-gray-900 hover:tracking-wider transition duration-250 ease-in-out">
                                    Services
                                </Link>
                            </li>
                            <li className="mb-2">
                                <Link to="/contact" className="text-red-900 hover:text-gray-900 hover:tracking-wider transition duration-250 ease-in-out">
                                    Contact
                                </Link>
                            </li>
                        </ul>
                    </div>

                    {/* 3rd Block - Specialties (Plain Text) */}
                    <div className="col-span-6 md:col-span-6 lg:col-span-4 mx-auto">
                        <h6 className="text-red-900 text-xl font-bold mb-4">Our Speciality</h6>
                        <ul className="text-md text-gray-700">
                            <li className="mb-2">Custom Designing</li>
                            <li className="mb-2">Advanced Manufacturing Solutions</li>
                            <li className="mb-2">Precision Quality & Compliance</li>
                            <li className="mb-2">Post-Production Support</li>
                        </ul>
                    </div>

                    {/* 4th Block - LinkedIn Link Only */}
                    <div className="col-span-12 text-center mx-auto lg:col-span-3 font-bold uppercase text-red-900">
                        <div className="text-xl mb-6">Follow Us on LinkedIn</div>
                        <div className="mx-auto text-center mt-2">
                            <a href="https://www.linkedin.com/company/gravity-techno" target="_blank" rel="noopener noreferrer" className="flex justify-center items-center text-red-900 hover:text-gray-500 bg-white hover:bg-gray-100 rounded-full shadow-lg transition duration-150 ease-in-out p-4">
                                <svg className="w-8 h-8 fill-current" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M4.98 3.5a2.5 2.5 0 01.01 5 2.5 2.5 0 01-.01-5zM4 8.75h2V20H4V8.75zM8.75 8.75h2V10c.25-.5.9-1.25 2.1-1.25 2.25 0 2.65 1.5 2.65 3.45V20h-2V12.75c0-1.25-.025-2.3-1.4-2.3-1.4 0-1.6 1.1-1.6 2.25V20h-2V8.75z" />
                                </svg>
                                <span className="ml-2 text-lg font-semibold">Gravity Techno</span>
                            </a>
                        </div>
                    </div>
                </div>

                {/* Copyright Section */}
                <div className="flex flex-wrap items-center md:justify-between justify-center mx-auto px-4">
                    <div className="w-full md:w-4/12 px-4 mx-auto text-center py-2">
                        <div className="text-sm text-gray-600 font-semibold py-1">
                            Copyright &copy; {new Date().getFullYear()}{" "}
                            <Link to="#" className="hover:text-gray-900">Gravity Techno</Link>. All rights reserved.
                        </div>
                    </div>
                </div>

            </div>
        </footer>
    );
};

export default Footer;
