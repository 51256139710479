import React, { useEffect } from 'react';
import AOS from 'aos';
import "aos/dist/aos.css";
import './index.css';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from 'react-router-dom';

// Import pages
import Home from './pages/Home';
import About from './pages/About';
import Contact from './pages/Contact';
import DemoProduct from './pages/DemoProduct';
import Services from './components/Services'
import Products from './components/Portfolio'


// Product Pages
import Product1 from './pages/Product_Pages/Product1';
import Product2 from './pages/Product_Pages/Product2';
import Product3 from './pages/Product_Pages/Product3';
import Product4 from './pages/Product_Pages/Product4';
import Product5 from './pages/Product_Pages/Product5';
import Product6 from './pages/Product_Pages/Product6';
import Product7 from './pages/Product_Pages/Product7';
import Product8 from './pages/Product_Pages/Product8';

import { useDocTitle } from './components/CustomHook';
import ScrollToTop from './components/ScrollToTop';

function App() {
  useEffect(() => {
    AOS.init({ once: true, duration: 1000, easing: 'ease-out-cubic' });
  }, []);

  useDocTitle("Gravity Techno | Innovating the Electronics ");

  return (
    <Router>
      <ScrollToTop>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/get-demo" element={<DemoProduct />} />
          <Route path="/services" element={<Services />} />  {/* ✅ Add Services Page Route */}
          <Route path="/products" element={<Products />} />
  

          {/* Product Pages Routing */}
          <Route path="/product/1" element={<Product1 />} />
          <Route path="/product/2" element={<Product2 />} />
          <Route path="/product/3" element={<Product3 />} />
          <Route path="/product/4" element={<Product4 />} />
          <Route path="/product/5" element={<Product5 />} />
          <Route path="/product/6" element={<Product6 />} />
          <Route path="/product/7" element={<Product7 />} />
          <Route path="/product/8" element={<Product8 />} />
        </Routes>
      </ScrollToTop>
    </Router>
  );
}

export default App;
