import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaArrowLeft, FaArrowRight, FaSearchPlus, FaTimes, FaFilePdf } from 'react-icons/fa';
import Navbar from '../../components/Navbar/NavBar';

// Import product images
import img1 from '../../images/products/M1_P2.1.png';
import img2 from '../../images/products/M1_P2.2.png';
import img3 from '../../images/products/M1_P2.3.png';
import img4 from '../../images/products/M1_P2.4.png';
import img5 from '../../images/products/M1_P2.5.png';
import img6 from '../../images/products/M1_P2.6.png';

// Import PDF file
import productPDF from '../../Manuals/P2.pdf';

// Import PDF Viewer
import { Worker } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';

const Product2 = () => {
  const navigate = useNavigate();
  const [currentImage, setCurrentImage] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isPdfOpen, setIsPdfOpen] = useState(false);
  const images = [img1, img2, img3, img4, img5, img6];

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' }); // Scrolls to top on mount
  }, []);

  const nextImage = () => {
    setCurrentImage((prev) => (prev + 1) % images.length);
  };

  const prevImage = () => {
    setCurrentImage((prev) => (prev - 1 + images.length) % images.length);
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const openPdfModal = () => {
    setIsPdfOpen(true);
  };

  const closePdfModal = () => {
    setIsPdfOpen(false);
  };

  return (
    <>
      <Navbar />

      <div className="min-h-screen bg-gray-100 flex flex-col items-center pt-24 p-6">
        {/* Product Title */}
        <h1 className="text-7xl font-extrabold text-blue-900 mb-12 text-center" style={{ fontFamily: 'Congenial Black' }}>
          Data Scanner
        </h1>

        <div className="flex flex-col sm:flex-row items-start w-full max-w-6xl">
          {/* Image Carousel */}
          <div className="relative w-[500px]">
            <button 
              className="absolute left-2 top-1/2 transform -translate-y-1/2 bg-gray-700 text-white p-2 rounded-full transition hover:bg-gray-900" 
              onClick={prevImage}
            >
              <FaArrowLeft />
            </button>

            <img 
              src={images[currentImage]} 
              alt="Data Scanner - ZemScan" 
              className="w-full rounded-lg shadow-lg cursor-pointer"
            />

            <button 
              className="absolute right-2 top-1/2 transform -translate-y-1/2 bg-gray-700 text-white p-2 rounded-full transition hover:bg-gray-900" 
              onClick={nextImage}
            >
              <FaArrowRight />
            </button>

            {/* Magnify Button */}
            <button 
              className="absolute top-2 right-2 bg-gray-700 text-white p-2 rounded-full transition hover:bg-gray-900"
              onClick={openModal}
            >
              <FaSearchPlus />
            </button>

            {/* PDF Button */}
            <div className="mt-4 flex justify-center">
              <button 
                className="px-4 py-2 bg-red-600 text-white rounded-lg flex items-center gap-2 hover:bg-red-800 transition"
                onClick={openPdfModal}
              >
                <FaFilePdf /> View PDF
              </button>
            </div>
          </div>

          {/* Product Details - Positioned on the Right */}
          <div className="ml-10 max-w-lg bg-white p-6 rounded-lg shadow-lg">
            <h2 className="text-2xl font-semibold mb-3 text-gray-800">Specifications</h2>
            <ul className="list-disc list-inside text-gray-700">
              <li><strong>Model:</strong> Data Scanner - ZemScan</li>
              <li><strong>Type:</strong> Process Data Logger</li>
              <li><strong>Usage:</strong> Cold Storages</li>
              <li><strong>Parameter:</strong> Pressure</li>
              <li><strong>Interface:</strong> RS485</li>
              <li><strong>Export Data:</strong> Excel</li>
              <li><strong>Display:</strong> LED</li>
              <li><strong>Dimensions:</strong> 96 x 96 mm</li>
              <li><strong>Brand:</strong> Gravity Techno</li>
              <li><strong>Channels:</strong> Dual Channel</li>
              <li><strong>Power Supply:</strong> Electric</li>
              <li><strong>Memory:</strong> 70% Alarm</li>
              <li><strong>Frequency:</strong> 50 Hz</li>
              <li><strong>Voltage:</strong> 265 VAC</li>
            </ul>
          </div>
        </div>

        <button 
          onClick={() => navigate(-1)} 
          className="mt-6 px-6 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-800 transition"
        >
          Go Back
        </button>
      </div>

      {/* Modal for Enlarged Image */}
      {isModalOpen && (
        <div 
          className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-75 flex justify-center items-center z-50"
          onClick={closeModal}
        >
          <div className="relative max-w-3xl">
            <img 
              src={images[currentImage]} 
              alt="Data Scanner - ZemScan Enlarged" 
              className="w-full rounded-lg"
            />
            <button 
              className="absolute top-3 right-3 bg-gray-700 text-white p-2 rounded-full transition hover:bg-gray-900"
              onClick={closeModal}
            >
              <FaTimes />
            </button>
          </div>
        </div>
      )}

      {/* PDF Modal */}
      {isPdfOpen && (
        <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-75 flex justify-center items-center z-50">
          <div className="relative bg-white w-3/4 h-3/4 p-4 rounded-lg shadow-lg">
            <button 
              className="absolute top-3 right-3 bg-gray-700 text-white p-2 rounded-full transition hover:bg-gray-900" 
              onClick={closePdfModal}
            >
              <FaTimes />
            </button>
            <div className="h-full overflow-auto">
              <Worker workerUrl={`https://unpkg.com/pdfjs-dist@2.14.305/build/pdf.worker.min.js`}>
                <iframe src={productPDF} className="w-full h-full" title="Product PDF"></iframe>
              </Worker>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Product2;
