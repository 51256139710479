import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaArrowLeft, FaArrowRight, FaSearchPlus, FaTimes } from 'react-icons/fa';
import Navbar from '../../components/Navbar/NavBar';

// Import product images
import img1 from '../../images/products/M1_P5.1.png';
import img2 from '../../images/products/M1_P5.2.png';
import img3 from '../../images/products/M1_P5.3.png';

const Product5 = () => {
  const navigate = useNavigate();
  const [currentImage, setCurrentImage] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const images = [img1, img2, img3];

  useEffect(() => {
      window.scrollTo(0, 0);
  }, []);

  const nextImage = () => {
    setCurrentImage((prev) => (prev + 1) % images.length);
  };

  const prevImage = () => {
    setCurrentImage((prev) => (prev - 1 + images.length) % images.length);
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Navbar />

      <div className="min-h-screen bg-gray-100 flex flex-col items-center pt-24 p-6">
      <h1 
          className="text-7xl font-extrabold text-blue-900 mb-12 text-center" style={{ fontFamily: 'Congenial Black' }}
        >
          PLC Controller
        </h1>

        <div className="flex flex-col sm:flex-row items-start w-full max-w-6xl">
          {/* Image Carousel */}
          <div className="relative w-[500px]">
            <button 
              className="absolute left-2 top-1/2 transform -translate-y-1/2 bg-gray-700 text-white p-2 rounded-full transition hover:bg-gray-900" 
              onClick={prevImage}
            >
              <FaArrowLeft />
            </button>

            <img 
              src={images[currentImage]} 
              alt="Industrial Controller" 
              className="w-full rounded-lg shadow-lg cursor-pointer"
            />

            <button 
              className="absolute right-2 top-1/2 transform -translate-y-1/2 bg-gray-700 text-white p-2 rounded-full transition hover:bg-gray-900" 
              onClick={nextImage}
            >
              <FaArrowRight />
            </button>

            {/* Magnify Button */}
            <button 
              className="absolute top-2 right-2 bg-gray-700 text-white p-2 rounded-full transition hover:bg-gray-900"
              onClick={openModal}
            >
              <FaSearchPlus />
            </button>
          </div>

          {/* Product Details */}
          <div className="ml-10 max-w-lg bg-white p-6 rounded-lg shadow-lg">
            <h2 className="text-2xl font-semibold mb-3 text-gray-800">Specifications</h2>
            <ul className="list-disc list-inside text-gray-700">
              <li><strong>Brand:</strong> Gravity Techno</li>
              <li><strong>Dimensions:</strong> 72x72x65 mm</li>
              <li><strong>No. of Ports:</strong> 1</li>
              <li><strong>Cable Length:</strong> 2.5 m</li>
              <li><strong>Color:</strong> Black</li>
              <li><strong>Number of Outputs:</strong> 8 / 16</li>
              <li><strong>Frequency:</strong> 50 Hz</li>
              <li><strong>Voltage:</strong> 265 VAC</li>
            </ul>
          </div>
        </div>

        <button 
          onClick={() => navigate(-1)} 
          className="mt-6 px-6 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-800 transition"
        >
          Go Back
        </button>
      </div>

      {/* Modal for Enlarged Image */}
      {isModalOpen && (
        <div 
          className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-75 flex justify-center items-center z-50"
          onClick={closeModal}
        >
          <div className="relative max-w-3xl">
            <img 
              src={images[currentImage]} 
              alt="Industrial Controller Enlarged" 
              className="w-full rounded-lg"
            />
            <button 
              className="absolute top-3 right-3 bg-gray-700 text-white p-2 rounded-full transition hover:bg-gray-900"
              onClick={closeModal}
            >
              <FaTimes />
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default Product5;
