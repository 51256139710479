import React from 'react';
import ketanShahImage from '../images/ketan-shah.jpeg';

const Cta = () => {
    return ( 
        <div className="w-full flex items-center justify-center text-white bg-gray-900 py-12 px-6">
            <div className="w-full max-w-4xl text-center flex flex-col items-center">
                <img src={ketanShahImage} alt="Ketan Shah" className="w-32 h-32 rounded-full mb-4 border-4 border-white shadow-lg" />
                <p className="text-lg md:text-2xl font-semibold mb-4 font-italic line-clamp-3">
                    "Innovation in electronics is not just about creating new gadgets, it's about pushing the boundaries of what's possible to enhance the way we live, work, and connect."
                </p>
                <p className="text-lg md:text-xl font-semibold">- Ketan Shah, Sole Proprietor</p>
            </div>
        </div>
    );
}
 
export default Cta;
